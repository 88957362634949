import * as types from "../constants/courseActionTypes.js";

const initialState = {
  courses: null,
  units: [],
  loading: false,
  success: false,
  failed: false,
  message: "",
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    // get courses
    case types.GET_COURSES:
      return {
        ...state,
        loading: true,
      };
    case types.GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        loading: false,
        success: true,
      };
    case types.GET_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload,
        courses: null,
      };

    case types.CLEAN_UP:
      return {
        ...state,
        loading: false,
        success: false,
        message: "",
      };

    default:
      return state;
  }
};

export default courseReducer;
