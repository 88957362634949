import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../constants/questionsActionTypes.js";
import * as api from "../features/questionsets/api/index.js";

function* getQuestionById({ payload }) {
  try {
    const question = yield call(api.getQuestionsById, payload);
    yield put({ type: types.GET_QUESTIONS_BY_ID_SUCCESS, payload: question });
  } catch (error) {
    yield put({
      type: types.GET_QUESTIONS_BY_ID_FAILED,
      payload: error,
    });
  }
}
function* getQuestionsByCourseId({ payload }) {
  try {
    const question = yield call(api.getQuestionsByCourseId, payload);
    yield put({
      type: types.GET_QUESTIONS_BY_COURSE_ID_SUCCESS,
      payload: question,
    });
  } catch (error) {
    yield put({
      type: types.GET_QUESTIONS_BY_COURSE_ID_FAILED,
      payload: error,
    });
  }
}

function* updateLike({ payload }) {
  try {
    const question = yield call(api.updateLike, payload);
    yield put({ type: types.ADD_LIKE_SUCCESS, payload: question });
  } catch (error) {
    yield put({ type: types.ADD_LIKE_FAILED, payload: error });
  }
}

function* questionSaga() {
  yield takeEvery(types.GET_QUESTIONS_BY_ID, getQuestionById);
  yield takeEvery(types.GET_QUESTIONS_BY_COURSE_ID, getQuestionsByCourseId);
  yield takeEvery(types.ADD_LIKE, updateLike);
}

export default questionSaga;
