import React, { useState } from "react";
import { CardContent, Typography, Stack, Box } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import QuestionDetail from "./questionDetail";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateLikes } from "../actions/questions";

const QuestionSetCard = ({ questionSet }) => {
  const { currentUser } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  const handleLike = () => {
    if (currentUser) {
      const data = {
        id: questionSet?.id,
        userId: currentUser?.id,
      };
      dispatch(updateLikes(data));
    }
  };

  const [openQuestionDetail, setOpenQuestionDetail] = useState(false);

  return (
    <Box
      sx={{
        width: { xs: "260px", md: "260px", lg: "300px" },
        minHeight: "200px",
        padding: "10px",
        "&:hover": {
          // cursor: "pointer",
        },
        boxShadow: "0px 0px 40px -25px rgba(0, 0, 0, 0.5)",
        border: "none !important",
        background: "white",
        borderRadius: "10px",
        maxHeight: "fit-content",
        // flex: 1,
      }}
    >
      {openQuestionDetail ? (
        <QuestionDetail
          openQuestionDetail={openQuestionDetail}
          setOpenQuestionDetail={setOpenQuestionDetail}
          questionSet={questionSet}
        />
      ) : (
        " "
      )}
      <CardContent>
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <Typography
            onClick={() => setOpenQuestionDetail(true)}
            sx={{
              color: "#078989",
              fontWeight: "bold",
              "&:hover": {
                cursor: "pointer",
              },
              fontSize: { xs: "20px", md: "22px" },
              mr: "20px",
            }}
          >
            {questionSet?.title?.slice(0, 20)}
          </Typography>
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
              zIndex: "5",
            }}
          >
            {questionSet?.likes?.includes(currentUser?.id) ? (
              <FavoriteIcon
                sx={{
                  color: "red",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleLike}
              />
            ) : (
              <FavoriteBorderIcon
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleLike}
              />
            )}
            <Typography>{questionSet?.likes?.length}</Typography>
          </Stack>
        </Stack>
        <Typography
          sx={{ mt: "10px", height: "80px" }}
          variant="body2"
          color="text.secondary"
        >
          {questionSet?.description}
        </Typography>
        <Stack
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            gap: "20px",
            position: "relative",
          }}
        >
          <Typography>{questionSet?.questionCount} questions</Typography>
        </Stack>
      </CardContent>
    </Box>
  );
};

export default QuestionSetCard;
