import axios from "axios";
import { API_BASE_URL } from "../../../config";

axios.defaults.withCredentials = true;

const transport = axios.create({
  withCredentials: true,
});
export const getQuestionsByCourseId = async (id) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL}/api/courses/${id}/questions`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getQuestionsById = async (id) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/api/questions/${id}`, {
      withCredentials: true,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateLike = async (file) => {
  try {
    const { data } = await transport.put(
      `${API_BASE_URL}/api/updateLike`,
      file
    );
    return data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};
