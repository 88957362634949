import axios from "axios";
import { API_BASE_URL } from "../../../config";

axios.defaults.withCredentials = true;
const transport = axios.create({
  withCredentials: true,
});

export const getCourses = async () => {
  try {
    const { data } = await transport.get(API_BASE_URL + "/api/courses");
    return data;
  } catch (error) {
    console.log("course error: ", error);
    throw new Error(error?.response?.data?.message || error?.message);
  }
};
