import * as constants from "../../../constants/questionsActionTypes.js";

export const getQuestionsById = (id) => {
  return { type: constants.GET_QUESTIONS_BY_ID, payload: id };
};

export const getQuestionsByCourseId = (id) => {
  return { type: constants.GET_QUESTIONS_BY_COURSE_ID, payload: id };
};

export const updateLikes = (data) => {
  return { type: constants.ADD_LIKE, payload: data };
};

export const cleanUpQuesions = () => {
  return { type: constants.CLEAN_UP_QUESTIONS };
};
