import * as types from "../constants/unitActionTypes.js";
const initialState = {
  units: null,
  loading: false,
  success: false,
  failed: false,
  message: "",
  isCreate: false,
  isUpdate: false,
  isDelete: false,
};

const unitReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_UNITS:
      return {
        ...state,
      };
    case types.GET_ALL_UNITS_SUCCESS:
      return {
        ...state,
        units: action.payload,
        loading: false,
        success: true,
      };
    case types.GET_ALL_UNITS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        message: action.payload.toString(),
      };

    // create course
    case types.CREATE_UNIT:
      return {
        ...state,
        loading: true,
      };

    case types.CLEAN_UP:
      return {
        ...state,
        loading: false,
        success: false,
        message: "",
        isCreate: false,
        isUpdate: false,
        isDelete: false,
      };

    // get courses

    default:
      return state;
  }
};

export default unitReducer;
