import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../constants/courseActionTypes.js";
import * as api from "../features/courses/api/index.js";

function* getCourses() {
  try {
    const courses = yield call(api.getCourses);
    yield put({ type: types.GET_COURSES_SUCCESS, payload: courses });
  } catch (error) {
    yield put({
      type: types.GET_COURSES_FAILED,
      payload: error,
    });
  }
}

function* courseSaga() {
  yield takeEvery(types.GET_COURSES, getCourses);
}

export default courseSaga;
