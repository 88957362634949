import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../config";
import { Stack, Box, Typography } from "@mui/material";

const CourseCard = (course) => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(`/course/${course?.course?.id}`)}
      className="course-card"
      sx={{
        width: { xs: "300px", md: "300px", lg: "350px" },
        height: { xs: "400px", md: "450px" },

        borderRadius: "20px",
        "&:hover": {
          cursor: "pointer",
        },
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "85%",
          margin: "30px 0px 0px 0px !important",
          height: { xs: "200px", md: "250px" },
          border: "1px solid silver",
          borderRadius: "20px",
        }}
      >
        <img
          alt={`${course?.course?.title || "course picture"}`}
          src={`${API_BASE_URL}/uploads/${course?.course?.image}`}
          width="100%"
          // height="250px"
          style={{
            borderRadius: "20px",
            height: "inherit",
            objectFit: "cover",
          }}
        />
      </Box>
      <CardContent sx={{ width: "85%", flex: "1" }}>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Typography
            onClick={() => navigate(`/course/${course?.course?.id}`)}
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              color: "#078989",
              fontWeight: "bold",
              "&:hover": {
                cursor: "pointer",
              },
              fontSize: { xs: "20px", md: "25px" },
              mr: "20px",
            }}
          >
            {course?.course?.title}
          </Typography>
        </Stack>
        <Typography
          gutterBottom
          component="div"
          sx={{
            color: "black",
          }}
        >
          {course?.course?.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CourseCard;
