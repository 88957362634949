import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../constants/unitActionTypes.js";
import * as api from "../features/units/api/index.js";

function* getAllUnits() {
  try {
    const units = yield call(api.getAllUnits);
    yield put({ type: types.GET_ALL_UNITS_SUCCESS, payload: units });
  } catch (error) {
    yield put({
      type: types.GET_ALL_UNITS_FAILED,
      payload: error,
    });
  }
}

function* unitSaga() {
  yield takeEvery(types.GET_ALL_UNITS, getAllUnits);
}

export default unitSaga;
