import axios from "axios";
import { API_BASE_URL } from "../../../config";

const transport = axios.create({
  withCredentials: true,
});

axios.defaults.withCredentials = true;

export const getAllUnits = async () => {
  try {
    const { data } = await transport.get(`${API_BASE_URL}/api/units`);
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error?.message);
  }
};
