import axios from "axios";
import { API_BASE_URL } from "../../../config";

axios.defaults.withCredentials = true;

const transport = axios.create({
  withCredentials: true,
});

export const logout = async () => {
  try {
    const { data } = await axios.delete(`${API_BASE_URL}/api/logout`);
    return data;
  } catch (error) {
    if (error.response) {
      throw new Error(error?.response?.data?.message);
    }
  }
};

export const forgetPassword = async (email) => {
  try {
    await transport.post(API_BASE_URL + "/api/forgetpassword/", email);

    return "success";
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const resetPassword = async (reset) => {
  try {
    await axios.post(API_BASE_URL + "/api/reset-password/" + reset.token, {
      password: reset.password,
    });

    return "success";
  } catch (error) {
    throw error?.response?.data?.message;
  }
};

export const getLoggedUser = async () => {
  try {
    const response = await axios.get(API_BASE_URL + "/api/login/success", {
      withCredentials: true,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (user) => {
  try {
    const { data } = await axios.post(API_BASE_URL + "/api/users", user);
    return data;
  } catch (error) {
    const message = error?.response?.data?.message;
    if (message === "email must be unique") {
      throw new Error("Email address already in use!");
    } else {
      throw new Error("Something went wrong, Please try again.");
    }
  }
};
export const sendMessage = async (message) => {
  try {
    await axios.post(API_BASE_URL + "/api/sendmessage", message);
    return "Message sent successfully";
  } catch (error) {
    throw error?.response?.data?.message || "Message not sent!";
  }
};

export const login = async (user) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/api/login`, user);
    return data;
  } catch (error) {
    if (error.response) {
      throw new Error(error?.response?.data?.message);
    }
  }
};

export const getCurrentUser = async (user_id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/user/${user_id}/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },
    });
    localStorage.setItem("loggedUser", JSON.stringify(response.data));
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateUser = async (user) => {
  try {
    await fetch(`${API_BASE_URL}/api/user/${user.id}/`, {
      method: "PUT",

      body: user.user,
    });

    const response = await axios.get(`${API_BASE_URL}user/${user?.id}/`, {
      method: "GET",
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
