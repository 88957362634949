import React, { useState, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import Header from "../../../components/Header.js";
import Search from "../../../components/Search.js";
import { useSelector } from "react-redux";

import CourseList from "../components/CourseList.js";
import Footer from "../../../components/Footer.js";
import BottomCurve from "../../../assets/home_bottom_curve.png";
import SpinnerComponent from "../../../components/Spinner.js";

const CoursesPage = () => {
  const { courses, loading } = useSelector((state) => state.courses);

  const [search, setSearch] = useState("");

  const handleSearch = (text) => {
    setSearch(text);
  };
  let filteredJobs = useMemo(
    () =>
      courses?.filter(
        (course) =>
          course["title"]?.toLowerCase()?.startsWith(search?.toLowerCase()),
        [search?.toLowerCase()]
      ),
    [courses, search]
  );

  return (
    <Box
      sx={{
        backgroundColor: "#f6f9fa !important",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      {loading && <SpinnerComponent />}
      <Header />
      <Box
        sx={{
          borderColor: "#f6f9fa !important",
          backgroundColor: "#f6f9fa !important",
          marginTop: "80px",
        }}
      >
        <Stack
          sx={{
            overflow: "auto",
            padding: "0px 30px 0px",
            gap: "20px",
            width: { xs: "85vw", md: "85vw", lg: "80vw" },
            margin: "auto auto auto",
          }}
        >
          <Box sx={{ mt: "20px" }}>
            <Search handleSearch={handleSearch} helperText="Search a course" />
          </Box>
          <CourseList filteredJobs={filteredJobs} />
        </Stack>
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: {
            // xs: "none",
            md: "flex",
            bottom: "00px",
            left: "0px",
            right: "0",
            margin: "50px auto 0px",
            height: "80px",
          },
        }}
        component="img"
        alt="curve"
        src={BottomCurve}
      ></Box>
      <Box sx={{ mt: "20px" }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default CoursesPage;
