import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LogInPage from "./features/authentication/pages/LoginPage.js";
import SignUpPage from "./features/authentication/pages/SignupPage.js";
import HomePage from "./pages/HomePage";
import { useSelector } from "react-redux";
import AboutPage from "./pages/AboutPage";
import QuizPage from "./pages/QuizPage";
import EmailSentSuccess from "./features/authentication/pages/EmailSentSuccess.js";
import ResetPassword from "./features/authentication/pages/ResetPassword";
import SendEmailToReset from "./features/authentication/pages/SendEmailToReset";
import TestimonialPage from "./pages/TestimonialPage";
import ContactPage from "./pages/ContactPage";
import CoursesPage from "./features/courses/pages/CoursesPage";
import CourseQuestionSetsPage from "./features/questionsets/pages/CourseQuestionSetsPage";
import { useDispatch } from "react-redux";
import { getCourses } from "./features/courses/actions/courses";
import { getAllUnits } from "./features/units/actions/units";
import { getLoggedUser } from "./features/authentication/actions/users";

const App = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getCourses());
    dispatch(getAllUnits());
  });

  useEffect(() => {
    if (!currentUser) {
      dispatch(getLoggedUser());
    }
  }, [dispatch, currentUser]);

  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/courses" exact element={<CoursesPage />} />
        <Route path="/forget-password" exact element={<SendEmailToReset />} />
        <Route
          path="/reset-password/:token"
          exact
          element={<ResetPassword />}
        />

        <Route path="/email-sent" exact element={<EmailSentSuccess />} />
        <Route path="/questions/:id" exact element={<QuizPage />} />
        <Route path="/course/:id" exact element={<CourseQuestionSetsPage />} />
        <Route path="/about" exact element={<AboutPage />} />
        <Route path="/testimonials" exact element={<TestimonialPage />} />
        <Route path="/contact" exact element={<ContactPage />} />

        <Route
          path="/login"
          exact
          element={currentUser ? <Navigate to="/courses" /> : <LogInPage />}
        />
        <Route
          path="/signup"
          exact
          element={currentUser ? <Navigate to="/courses" /> : <SignUpPage />}
        />
      </Routes>
    </Router>
  );
};

export default App;
